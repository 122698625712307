import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Message from '../../enb-styled/Message.js';
import TableListCompentencies from '../../markdowns/sections/docs/training-and-qualifications/response-list-qualifications.md';
import TableCreateCompentency from '../../markdowns/sections/docs/training-and-qualifications/parameters-create-competency.md';
import TableRetrieveCompentency from '../../markdowns/sections/docs/training-and-qualifications/response-retrieve-competency.md';
import TableUpdateCompentency from '../../markdowns/sections/docs/training-and-qualifications/parameters-update-competency.md';
import TableAssignCompentency from '../../markdowns/sections/docs/training-and-qualifications/parameters-assign-competency.md';
import TableListAllocatedRoleCompentencies from '../../markdowns/sections/docs/training-and-qualifications/response-list-allocated-role-competencies.md';
import TableAllocateEmployeeCompentencies from '../../markdowns/sections/docs/training-and-qualifications/parameters-allocate-competency-employee.md';
import TableRetrieveEmployeeCompentency from '../../markdowns/sections/docs/training-and-qualifications/response-retrieve-competency-employee.md';
import TableUpdateEmployeeCompentency from '../../markdowns/sections/docs/training-and-qualifications/response-update-competency-employee.md';
import TableListRoles from '../../markdowns/sections/docs/training-and-qualifications/response-list-roles.md';
import TableRetrieveRole from '../../markdowns/sections/docs/training-and-qualifications/response-retrieve-role.md';
import TableCreateRole from '../../markdowns/sections/docs/training-and-qualifications/parameters-create-role.md';
import TableUpdateRole from '../../markdowns/sections/docs/training-and-qualifications/parameters-update-role.md';
import TableAllocateRoleEmployee from '../../markdowns/sections/docs/training-and-qualifications/parameters-allocate-role-employee.md';
import TableListGroups from '../../markdowns/sections/docs/training-and-qualifications/response-list-groups.md';
import TableCreateGroup from '../../markdowns/sections/docs/training-and-qualifications/parameters-create-group.md';
import TableRetieveGroup from '../../markdowns/sections/docs/training-and-qualifications/response-retrieve-group.md';
import TableUpdateGroup from '../../markdowns/sections/docs/training-and-qualifications/parameters-update-group.md';
export const _frontmatter = {
  "title": "Training and Qualifications"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Training and Qualifications`}</h1>
    <div id="definitions">
      <h2>{`Definitions`}</h2>
      <p>{`With enableHR, an organisation can track Training & Qualification data about their Candidates, Employees, Volunteers and Contractors.`}</p>
      <p>{`Currently, the Open API only exposes Training & Qualification module for Employees.`}</p>
      <p>{`Training & Qualification items are called `}<strong parentName="p">{`Competencies`}</strong>{` and are used to track a broad range of functions and role requirements such as the need to attend regular training courses, ensuring relevant qualifications like licenses, proof of vaccination and certificates are always up to date.`}</p>
      <p>{`Examples of Competencies are:`}</p>
      <ul>
        <li parentName="ul">{`Qualification (e.g. CPR Certification, Diploma of Business, White Card, etc)`}</li>
        <li parentName="ul">{`Training (e.g. Advanced Excel training course, Infection Control Training, WHS inductions)`}</li>
        <li parentName="ul">{`License (e.g. Driver’s License, Forklift License, Working with Children Check)`}</li>
        <li parentName="ul">{`Skills (e.g. Touch typing, Project management, Fire Warden training)`}</li>
        <li parentName="ul">{`Medical (e.g. Vaccination Status, First Aid)`}</li>
        <li parentName="ul">{`Professional Registration / Membership (e.g. Unions, Chartered Accountants, Engineering Technician)`}</li>
        <li parentName="ul">{`Capability (e.g. Problem solving, Leadership, Initiative)`}</li>
      </ul>
      <p>{`When Competencies are used to track training sessions, they can be assigned `}<strong parentName="p">{`Points`}</strong>{` which are used to denote the weighting of a certain training session that a worker has completed. The point system is more prevalent in accounting, legal and healthcare industries where workers need to complete a certain number of points per year to maintain their Practising Certificate.`}</p>
      <p>{`To make management of Competencies easier in the User Interface, they can be organised in `}<strong parentName="p">{`Groups`}</strong>{` and Sub-Groups. Groups cannot be allocated to an Employee. Groups are to Competencies what Folders are to Files. An individual Competency can be allocated directly to an Employee, but you can also group several Competencies into a `}<strong parentName="p">{`Role`}</strong>{` and assign the Role to the Employee. When you do this, all the Competencies within that Role are allocated to the Employee, at once.`}</p>
      <p>{`Roles reflect the organisation’s job types and functions.  (Appropriate competencies that are relevant to a specific role or function can be grouped together).  For instance, if your organisation has a sales team where all employees require the same Competencies, you can create a Role called ‘Sales’ and assign the relevant Competencies like Sales Qualification, or Driver’s License to that role.`}</p>
      <p>{`Groups can represent a collection of Roles in the User Interface. `}</p>
      <p>{`Once Competencies have been allocated to Employees, the final step requires each Competency to be updated to capture the status and details of each skill, training, and qualification requirement. This is a very important step as it ensures compliance and will trigger a range of alerts for any Competencies that have expiry dates.  `}</p>
    </div>
    <div id="competency-id">
      <h2>{`Competencies`}</h2>
      <h3>{`List all Competencies`}</h3>
      <p>{`Use this action to list all the Competencies created in the account.`}</p>
      <Message mdxType="Message">
Competencies can be standalone or part of a Group. Groups are used to organise Competencies together. They are to Competencies what Folders are to Files.
        <br />
Using Groups makes management through the User Interface easier.
        <br />
If a Competency is part of a Group (like a file would be sitting in a folder), the “Parent Group” fields in the table below will indicate this. If they are not part of a Group, then the “Parent Group” fields will be empty.
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/list-all-competencies.png" alt="List All Competencies" style={{
          "maxWidth": "60%"
        }} />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/ListCompetencies/"
                }}>{`GET: List Competencies`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Response`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableListCompentencies mdxType="TableListCompentencies" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/competencies.png" alt="Competencies" style={{
          "maxWidth": "60%"
        }} />
      </div>
      <h3>{`Create a Competency`}</h3>
      <p>{`Use this action to create a new Competency.`}</p>
      <Message mdxType="Message">
Competencies are created at the Account level and can be standalone or part of a Group (of Competencies).
Groups are used to organise Competencies together. They are to Competencies what Folders are to Files.
        <p>{`Using Groups makes management through the User Interface easier.
If the Competency you created is part of a Group (like a file would be sitting in a folder), you need to first get the Unique ID of that “Parent Group” provided the Group exists.`}</p>
        <p>{`Competencies can be assigned a default expiry date and can lapse after a certain amount of time. The expiry date is a combination of ‘Expires after- value' and ‘Expires after- unit'. These two properties will be the default values when the Competency is allocated to an Employee.`}</p>
        <ul>
          <li parentName="ul">{`If there is no date set in the 'Expires after' field of the competency. As soon as an expiry date is added to the competency (through the User Interface), then the competency status will change from Completed, to Expired after the expiry date has elapsed.`}</li>
          <li parentName="ul">{`If there is a date set in the 'Expires after' field of the competency. When a completed date is added to the competency and the ‘Expires after’ date exists then the system will calculate the expiry date and change the status as in #1.`}</li>
        </ul>
        <p>{`Note: there are automated notification emails to managers regarding expired competencies, see "Training & Qualification" alerts `}<a parentName="p" {...{
            "href": "https://support.enablehr.com/hc/en-us/articles/207037616-What-actions-trigger-which-alerts-"
          }}>{`here`}</a></p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/create-competency.png" alt="Create a Competency" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/CreateCompetency/"
                }}>{`POST: Create Competency`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableCreateCompentency mdxType="TableCreateCompentency" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/create-competency-ui.png" alt="Create competency" />
      </div>
      <h3>{`Retrieve a Competency`}</h3>
      <p>{`Use this action to retrieve the details for an existing Competency.`}</p>
      <Message mdxType="Message">
Competencies can be a part of a Group or not. Groups are used to organise Competencies together. They are to Competencies what Folders are to Files.
        <br />
Using Groups makes management of Competencies through the User Interface easier.
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/retrieve-compentency.png" alt="Retrieve competency" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/RetrieveCompetency/"
                }}>{`GET: Retrieve Competency Details`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "#competency-id"
                }}>{`Competency ID`}</a>{` - Unique identifier of the Competency`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Response`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableRetrieveCompentency mdxType="TableRetrieveCompentency" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/retrieve-compentency-ui.png" alt="Retrieve competency" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <h3>{`Update a Competency`}</h3>
      <p>{`Use this action to update details for an existing Competency.`}</p>
      <Message mdxType="Message">
        <p>Competencies can be a part of a Group or not. Groups are used to organise Competencies together. They are to Competencies what Folders are to Files.</p>
        <p>Groups makes management of Competencies through the User Interface easier.</p>
        <p>You can use the “Update Competency” action to move a Competency between Groups, provided the destination Group exists.<br />
Competencies can be associated to a default due date and can expire after a certain amount of time. The due date is a combination of ‘Expires after- value' and ‘Expires after- unit'. These two properties will be the default values when the Competency is allocated to an Employee. They can be changed with the “Update Competency” action.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/update-competency.png" alt="Update competency" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/UpdateCompetency/"
                }}>{`PUT: Update Competency`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "#competency-id"
                }}>{`Competency ID`}</a>{` - Unique identifier of the Compentency`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableUpdateCompentency mdxType="TableUpdateCompentency" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/update-competency-ui.png" alt="Update competency" />
      </div>
      <h3>{`Delete a Competency`}</h3>
      <p>{`Use this action to delete an existing Competency.`}</p>
      <Message mdxType="Message">
        <p>When you delete a Competency at the Account level, it gets disassociated from all the Employees that had that Competency. It is recommended to look for Employees who have been allocated a Competency before deleting it.</p>
        <p>When a Competency is deleted, it is also removed from any Role it was assigned to.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/delete-competency.png" alt="Delete competency" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/DeleteCompetency/"
                }}>{`POST: Delete Competency`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "#competency-id"
                }}>{`Competency ID`}</a>{` - Unique identifier of the Compentency`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`None`}</strong>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/delete-competency-ui.png" alt="Delete competency" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <h3>{`Assign a Competency to a Role`}</h3>
      <p>{`Use this action to assign an existing Competency to an existing Role. `}</p>
      <Message mdxType="Message">
        <p>Once created, Competencies must be allocated to Employees. There are two ways to do this:</p>
        <ol>
          <li>You can allocate an individual Competency to the Employee, or</li>
          <li>You can include several Competencies in a Role and allocate the Role (and therefore all the Competencies it includes) to the Employee.</li>
        </ol>
        <p>If you want to use a Role, you will first need to create the Role and assign the Competencies to this Role.<br />
This action allows you to assign multiple Competency to a Role,  by separating the Competencies IDs with comas as per the table below.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/assign-competency-role.png" alt="Assign competency to a role" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/AssignCompetenciesToTrainingRole/"
                }}>{`POST: Assign Competency to a Role`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "#role-id"
                }}>{`Role ID`}</a>{` - Unique identifier of the Role`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableAssignCompentency mdxType="TableAssignCompentency" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/assign-competency-role-ui.png" alt="Assign competency to a role" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <h3>{`List Competencies allocated to an Employee`}</h3>
      <p>{`Use this action to list all the Competencies assigned to an Employee, including those that were allocated as part of a Role.`}</p>
      <Message mdxType="Message">
        <p>Once Competencies are created at the Account level, they can be allocated to Employees. There are two ways to do this:</p>
        <ol>
          <li>You can allocate an individual Competency to the Employee, or</li>
          <li>You can include several Competencies in a Role and allocate the Role (and therefore all the Competencies it includes) to the Employee.</li>
        </ol>
        <p>When you use the “List Competencies allocated to an Employee” action, you see:</p>
        <ol>
          <li>The Roles that were allocated to the Employee</li>
          <li>The Competencies that were individually allocated to the Employee, and</li>
          <li>The Competencies that were allocated to the Employee via a Role.</li>
        </ol>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/list-allocated-competencies.png" alt="List competency allocated to an Employee" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/ListEmployeeCompetencies/"
                }}>{`GET: List Competencies allocated to an Employee`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "/docs/employees/#list-all-employees"
                }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Response`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableListAllocatedRoleCompentencies mdxType="TableListAllocatedRoleCompentencies" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/list-allocated-competencies-ui.png" alt="List competency allocated to an Employee" />
      </div>
      <h3>{`Allocate a Competency to an Employee`}</h3>
      <p>{`Use this action to allocate an existing Competency to an Employee.`}</p>
      <Message mdxType="Message">
        <p>Once created, Competencies must be allocated to Employees. There are two ways to do this:</p>
        <ol>
          <li>Allocate an individual Competency to the Employee, or</li>
          <li>Include several Competencies in a Role and allocate the Role (and therefore all the Competencies it includes) to the Employee.</li>
        </ol>
        <p>You can only allocate one Competency at a time to an Employee. To allocate more than one Competency at a time, you must use Roles. <br />
When you allocate a Competency to an Employee, it inherits the “Expiry Date” and “Points” from the Competency. You can overwrite them as you allocate the Competency.<br />
To change any other data, you need to use the Update </p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/assign-competency-employee.png" alt="Assign competency to an employee" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/AllocateEmployeeCompetency/"
                }}>{`POST: Allocate Competency to an Employee`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "/docs/employees/#list-all-employees"
                }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableAllocateEmployeeCompentencies mdxType="TableAllocateEmployeeCompentencies" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3>{`Retrieve the details of a Competency for an Employee`}</h3>
      <p>{`Use this action to get the details of a Competency that is allocated to an Employee.`}</p>
      <Message mdxType="Message">
        <p>You can use this action for Competencies that were allocated to an Employee either individually or as part of a Role.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/retrieve-competency-employee.png" alt="Retrieve the details of a Competency for an Employee" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/ListEmployeeCompetencies/"
                }}>{`GET: List Competency details for an Employee`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "/docs/employees/#list-all-employees"
                }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}<br /><a parentName="td" {...{
                  "href": "#competency-id"
                }}>{`Competency ID`}</a>{` - Unique identifier of the Competency`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Response`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableRetrieveEmployeeCompentency mdxType="TableRetrieveEmployeeCompentency" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/retireve-competency-employee-ui.png" alt="Retrieve competency allocated to an Employee" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <h3>{`Update the details of a Competency for an Employee`}</h3>
      <p>{`Use this action to update the details of a Competency that is allocated to an Employee.`}</p>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/update-competency-employee.png" alt="Update competency of an employee" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/UpdateEmployeeCompetency/"
                }}>{`PUT: Update Competency details for an Employee`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "/docs/employees/#list-all-employees"
                }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}<br /><a parentName="td" {...{
                  "href": "#competency-id"
                }}>{`Competency ID`}</a>{` - Unique identifier of the Competency`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableUpdateEmployeeCompentency mdxType="TableUpdateEmployeeCompentency" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/update-competency-employee-ui.png" alt="Update competency of an Employee" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <h3>{`Unassign a Competency from an Employee`}</h3>
      <p>{`Use this action to remove a Competency assigned to an Employee.`}</p>
      <Message mdxType="Message">
        <p>
You can only unassign a Competency that was allocated individually and not as part as a Role.<br />
If the Competency was allocated as part of a Role, you need to unassign the Role, and all the Competencies within that Role.<br />
Alternatively, you could also update the status of the Competency to 'Not applicable' for that Employee.
        </p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/unallocate-competency-employee.png" alt="Unassign competency of an employee" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/DeleteEmployeeCompetency/"
                }}>{`DEL: Unassign Competency from an Employee`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "/docs/employees/#list-all-employees"
                }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}<br /><a parentName="td" {...{
                  "href": "#competency-id"
                }}>{`Competency ID`}</a>{` - Unique identifier of the Competency`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`None`}</strong>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/unallocate-competency-employee-ui.png" alt="Unassign competency of an employee" style={{
          "maxWidth": "80%"
        }} />
      </div>
    </div>
    <div id="role-id">
      <h2>{`Roles`}</h2>
      <h3>{`List all roles`}</h3>
      <p>{`Use this action to list all Roles in the account.`}</p>
      <Message mdxType="Message">
        <p>Competencies, once created, must be allocated to Employees. There are two ways to do this:</p>
        <ol>
          <li>Allocate an individual Competency to the Employee, or</li>
          <li>Include several Competencies in a Role and allocate the Role (and therefore all the Competencies it includes) to the Employee.</li>
        </ol>
        <p>To use a Role, you will first need to create the Role and assign Competencies to this Role. </p>
        <p>Roles can also be organised into Groups, just like Files can be organised into Folders. Groups are used to make Role management easier in the User Interface.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/list-roles.png" alt="List Roles" style={{
          "maxWidth": "50%"
        }} />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/ListTrainingRoles/"
                }}>{`GET: List Roles`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Response`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableListRoles mdxType="TableListRoles" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/list-roles-ui.png" alt="List Roles" style={{
          "maxWidth": "50%"
        }} />
      </div>
      <h2>{`Retrieve a Role`}</h2>
      <p>{`Use this action to retrieve the details of a Role.`}</p>
      <Message mdxType="Message">
        <p>Competencies must be created at the account level before they can be allocated to Employees. There are two ways to do this:</p>
        <ol>
          <li>Allocate an individual Competency to the Employee, or</li>
          <li>Include several Competencies in a Role and allocate the Role (and therefore all the Competencies it includes) to the Employee.</li>
        </ol>
        <p>To use a Role, you will first need to create the Role and assign the Competencies to this Role.</p>
        <p>When you create a Role, you can associate a tag to it (identifier). This is useful in the User Interface.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/retrieve-role.png" alt="Retrieve Role" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/RetrieveTrainingRole/"
                }}>{`GET: Retrieve details about a Role`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "#role-id"
                }}>{`Role ID`}</a>{` - Unique identifier of the Role`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Response`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableRetrieveRole mdxType="TableRetrieveRole" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/retrieve-role-ui.png" alt="Retrieve Roles" style={{
          "maxWidth": "90%"
        }} />
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/retrieve-role-ui-2.png" alt="Retrieve Roles" style={{
          "maxWidth": "90%"
        }} />
      </div>
      <h2>{`Create a Role`}</h2>
      <p>{`Use this action to create a new Role.`}</p>
      <Message mdxType="Message">
        <p>Competencies must be created at the Account level before they can be allocated to Employees. There are two ways to do this:</p>
        <ol>
          <li>Allocate an individual Competency to the Employee, or</li>
          <li>Include several Competencies in a Role and allocate the Role (and therefore all the Competencies it includes) to the Employee.</li>
        </ol>
        <p>To use a Role, you will first need to create the Role and assign the Competencies to this Role. </p>
        <p>When you create a Role, you can associate a tag to it (identifier). This is useful in the User Interface.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/create-role.png" alt="Create Role" style={{
          "maxWidth": "50%"
        }} />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/CreateTrainingRole/"
                }}>{`POST: Create a new Role`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableCreateRole mdxType="TableCreateRole" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/create-role-ui.png" alt="Create Role" />
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/create-role-ui-2.png" alt="Create Role" />
      </div>
      <h2>{`Update a Role`}</h2>
      <p>{`Use this action to update an existing role.`}</p>
      <Message mdxType="Message">
Roles are used to group Competencies together to make allocation to Employees easier. Instead of having to allocate each Competency separately, you can allocate the Role and every Competency included in the Role to an employee at the same time.
        <br />
You can update the Name, Description, Identifier and Group ID of a Role.
        <br />
This action cannot be used to change the Competencies assigned to a Role. Instead, you need to use the “Assign a Competency to a Role” or “Unassign a Competency from a Role” action.
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/update-role.png" alt="Update Role" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/UpdateTrainingRole/"
                }}>{`PUT: Update Role`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "#role-id"
                }}>{`Role ID`}</a>{` - Unique identifier of the Role`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableCreateRole mdxType="TableCreateRole" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/update-role-ui.png" alt="Update Role" />
      </div>
      <h2>{`Delete a Role`}</h2>
      <p>{`Use this action to delete an existing role.`}</p>
      <Message mdxType="Message">
Roles are used to group Competencies together to make allocation to Employees easier. Instead of having to allocate each Competency separately, you can allocate the Role and every Competency included in the Role to an employee at the same time.
        <br />
When you delete a Role that has been allocated to an Employee, the Competencies within this Role remains allocated to the Employee.
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/delete-role.png" alt="Delete Role" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/DeleteTrainingRole/"
                }}>{`DEL: Delete Role`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "#role-id"
                }}>{`Role ID`}</a>{` - Unique identifier of the Role`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`None`}</strong>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/delete-role-ui.png" alt="Delete Role" />
      </div>
      <h2>{`Allocate a Role to an Employee`}</h2>
      <p>{`Use this action to allocate a Role to an Employee.`}</p>
      <Message mdxType="Message">
        <p>Roles are used to group Competencies together to make allocation to Employees easier. Instead of having to allocate each Competency separately, you can allocate the Role to the Employee, and all the Competencies included in the Role will be allocated to the Employee at the same time.</p>
        <p>Note: If you allocate a Competency individually to an Employee and later allocate a Role that includes the same Competency, then the Employee will have this Competency twice.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/allocate-role-employee.png" alt="Allocate Role to Employee" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/AllocateEmployeeRole/"
                }}>{`POST: Allocate Role to Employee`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "/docs/employees/#list-all-employees"
                }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableAllocateRoleEmployee mdxType="TableAllocateRoleEmployee" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/allocate-role-employee-ui.png" alt="Allocate Role to Employee" />
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/allocate-role-employee-ui-2.jpg" alt="Allocate Role to Employee" />
      </div>
      <h2>{`Unassign a Role from an Employee`}</h2>
      <p>{`Use this action to  unassign a Role form an Employee.`}</p>
      <Message mdxType="Message">
Roles are used to group Competencies together to make allocation to Employees easier. Instead of having to allocate each Competency separately, you can allocate the Role to the Employee, and all the Competencies included in the Role will be allocated to the Employee at the same time.
        <br />
When you unassign a Role from an Employee, the Competencies in the Role will remain allocated to the Employee.
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/unassign-role-employee.png" alt="Unassign a Role from an Employee" />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/DeleteEmployeeRole/"
                }}>{`DEL: Unassign Role from an Employee`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "/docs/employees/#list-all-employees"
                }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}<br /><a parentName="td" {...{
                  "href": "#role-id"
                }}>{`Role ID`}</a>{` - Unique identifier of the Role`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`None`}</strong>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/unassign-role-employee-ui.png" alt="Unassign a Role from an Employee" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/unassign-role-employee-ui-2.png" alt="Unassign a Role from an Employee" style={{
          "maxWidth": "80%"
        }} />
      </div>
    </div>
    <div id="group-id">
      <h1>{`Groups`}</h1>
      <h2>{`Definitions`}</h2>
      <p>{`Groups are used in the User Interface to make the management of Roles and Competencies easier. `}</p>
      <p>{`Groups are like folders in which you would organize your files.`}</p>
      <p>{`A Group can be of one of two types:`}</p>
      <ul>
        <li parentName="ul">{`Competency Groups are used to group Competencies together. `}</li>
        <li parentName="ul">{`Role Groups are used to group Roles together. `}</li>
      </ul>
      <p>{`Using Groups makes management of Competencies through the User Interface easier`}</p>
      <h2>{`List all Groups`}</h2>
      <p>{`Use this action to list all Groups in the account.`}</p>
      <Message mdxType="Message">
        <p>Competencies and Roles can be organised in Groups, just like files can be organised in folders.<br />
Both Competencies and Roles cannot co-exist in a Group: it is either for Competencies or for Roles.</p>
        <p>Groups can be organised in a “tree-like hierarchy”, where each Group can have multiple Sub-Groups.<br />
All Groups withing a hierarchy must be of the same type. In other words, a “Competency-type Group” cannot have a Parent or Child Group that is a “Role-type Group”.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/list-groups.png" alt="List all Groups" style={{
          "maxWidth": "60%"
        }} />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/ListTrainingGroups/"
                }}>{`GET: List Groups`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableListGroups mdxType="TableListGroups" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/list-groups-ui.png" alt="List all Groups" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <h2>{`Create a Group`}</h2>
      <p>{`Use this action to create a Group.`}</p>
      <Message mdxType="Message">
        <p>Competencies and Roles can be organised in Groups, just like files can be organised in folders.</p>
        <p>When you create a Group, you need to specify its type: Competency or Role.</p>
        <p>A “tree-like hierarchy” can be created between Groups:</p>
        <ul>
          <li>A Group can have only one “Parent Group”</li>
          <li>A Parent Group can have several “Child Groups”</li>
        </ul>
        <p>When you create a Group, you can attach it underneath a Parent of the same type only.</p>
        <p>A Parent Group and Child Group cannot be linked   at the same time instead,</p>
        <ol>
          <li>Create the Parent Group</li>
          <li>Create each Child Group separately and attach them to the Parent.</li>
        </ol>
        <p>If you don’t indicate a Parent Group ID, then the Group will sit at the top level. There can be as many Groups as you want at the top level.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/create-group.png" alt="Create Group" style={{
          "maxWidth": "60%"
        }} />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/CreateTrainingGroup/"
                }}>{`POST: Create Group`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Fields`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableCreateGroup mdxType="TableCreateGroup" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/create-group-ui.png" alt="Create Group" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <h2>{`Retrieve the details of a Group`}</h2>
      <p>{`Use this action to retrieve the details of a Group.`}</p>
      <Message mdxType="Message">
        <p>Competencies and Roles can be organised in Groups, just like files can be organised in folders.<br />
A Group can have several Sub-Groups, but only one Parent.<br />
Retrieving the details of the Group doesn’t give you the list of Competencies (or Roles) it includes.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/retrieve-group.png" alt="Retrieve Group" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/RetrieveTrainingGroup/"
                }}>{`GET: Retrieve Group`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "#group-id"
                }}>{`Group ID`}</a>{` - Unique identifier of the Group`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableRetieveGroup mdxType="TableRetieveGroup" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/retrieve-group-ui.png" alt="Retrieve Group" style={{
          "maxWidth": "70%"
        }} />
      </div>
      <h2>{`Update a Group`}</h2>
      <p>{`Use this action to update a Group.`}</p>
      <Message mdxType="Message">
        <p>Competencies and Roles can be organised in Groups, just like files can be organised in folders.</p>
        <p>You can update the ‘Name’ and ‘Type’ of a Group</p>
        <p>This action is used to move a Group from one Parent Group to another Parent Group of the same type (so the Group and its Parent are of the same type).</p>
        <p>If you don’t indicate a Parent Group ID, then the Group will  move to the top level.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/update-group.png" alt="Update Group" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/UpdateTrainingGroup/"
                }}>{`PUT: Update Group`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "#group-id"
                }}>{`Group ID`}</a>{` - Unique identifier of the Group`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><TableUpdateGroup mdxType="TableUpdateGroup" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/update-group-ui.png" alt="Update Group" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <h2>{`Delete a Group`}</h2>
      <p>{`Use this action to delete a Group.`}</p>
      <Message mdxType="Message">
        <p>Competencies and Roles can be organised in Groups, just like files can be organised in folders. </p>
        <p>Deleting the Group will also delete any Child Group attached to it.</p>
        <p>When a Group is deleted, the Competencies (or Roles) that were associated to it remain in the system.<br />
If these Competencies (or Roles) were allocated to Employees, these allocations remain.</p>
      </Message>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/delete-group.png" alt="Delete Group" style={{
          "maxWidth": "80%"
        }} />
      </div>
      <div className="markdown-section">
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}></th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Method`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/development/DeleteTrainingGroup/"
                }}>{`POST: Delete Group`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/docs/accounts-and-branches/#account-id"
                }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                  "href": "#group-id"
                }}>{`Group ID`}</a>{` - Unique identifier of the Group`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`Accepted Parameters`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`None`}</strong>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="m-5 text-center">
    <img src="/images/docs/training-and-qualifications/delete-group-ui-1.png" alt="Delete Group" style={{
          "maxWidth": "90%"
        }} />
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      